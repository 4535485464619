import Vue from 'vue'
import {
  Image,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Progress,
  Message,
  MessageBox,
  Loading
} from 'element-ui'

Vue.use(Image)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(Progress)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message

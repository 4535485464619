const domain = 'https://www.speakey.com'
const apiUrl = 'https://webapi.speakey.com'

export default {
  // constants
  domain,
  firebaseConfig: {
    apiKey: "AIzaSyDRtLlqRAM403ps1FofIF8rTykwGaf5fVg",
    authDomain: "speakey-project.firebaseapp.com",
    projectId: "speakey-project",
    storageBucket: "speakey-project.appspot.com",
    messagingSenderId: "63456515790",
    appId: "1:63456515790:web:da31f683648b76f03aa29f",
    measurementId: "G-VX0R6MPM6B"
  },

  // langs
  baseLangs: ["en", "de", "es", "fr", "id", "it", "jp", "kr", "pl", "pt", "ru", "th", "tr", "vt", "cn", "tch"],
  langsFullNameMap: {
    "en": {
      "en": "English",
      "de": "German",
      "es": "Spanish",
      "fr": "French",
      "id": "Indonesian",
      "it": "Italian",
      "jp": "Japanese",
      "kr": "Korean",
      "pl": "Polish",
      "pt": "Portuguese",
      "ru": "Russian",
      "th": "Thai",
      "tr": "Turkish",
      "vt": "Vietnamese",
      "cn": "Chinese",
      "tch": "Traditional Chinese",
    },
    "de": {
      "en": "Englisch",
      "de": "Deutsch",
      "es": "Spanisch",
      "fr": "Französisch",
      "id": "Indonesisch",
      "it": "Italienisch",
      "jp": "Japanisch",
      "kr": "Koreanisch",
      "pl": "Polieren",
      "pt": "Portugiesisch",
      "ru": "Russisch",
      "th": "Thailändisch",
      "tr": "Türkisch",
      "vt": "Vietnamesisch",
      "cn": "Chinesisch",
      "tch": "Traditionelles Chinesisch",
    },
    "es": {
      "en": "Inglés",
      "de": "Alemán",
      "es": "Español",
      "fr": "Francés",
      "id": "Indonesio",
      "it": "Italiano",
      "jp": "Japonés",
      "kr": "Coreano",
      "pl": "Polaco",
      "pt": "Portugués",
      "ru": "Ruso",
      "th": "Tailandés",
      "tr": "Turco",
      "vt": "Vietnamita",
      "cn": "Chino",
      "tch": "Chino Tradicional",
    },
    "fr": {
      "en": "Anglais",
      "de": "Allemand",
      "es": "Espagnol",
      "fr": "Français",
      "id": "Indonésien",
      "it": "Italien",
      "jp": "Japonais",
      "kr": "Coréen",
      "pl": "Polonais",
      "pt": "Portugais",
      "ru": "Russe",
      "th": "Thaïlandais",
      "tr": "Turc",
      "vt": "Vietnamien",
      "cn": "Chinois",
      "tch": "Chinois Traditionnel",
    },
    "id": {
      "en": "Bahasa Inggris",
      "de": "Bahasa Jerman",
      "es": "Bahasa Spanyol",
      "fr": "Bahasa Perancis",
      "id": "Bahasa Indonesia",
      "it": "Bahasa Itali",
      "jp": "Bahasa Jepang",
      "kr": "Bahasa Korea",
      "pl": "Bahasa Polandia",
      "pt": "Bahasa Portugis",
      "ru": "Bahasa Rusia",
      "th": "Bahasa Thai",
      "tr": "Bahasa Turki",
      "vt": "Bahasa Vietnam",
      "cn": "Mandarin",
      "tch": "Mandarin Tradisional",
    },
    "it": {
      "en": "Inglese",
      "de": "Tedesco",
      "es": "Spagnolo",
      "fr": "Francese",
      "id": "Indonesiano",
      "it": "Italiano",
      "jp": "Giapponese",
      "kr": "Coreano",
      "pl": "Polacco",
      "pt": "Portoghese",
      "ru": "Russo",
      "th": "Tailandese",
      "tr": "Turco",
      "vt": "Vietnamita",
      "cn": "Cinese",
      "tch": "Cinese Tradizionale",
    },
    "jp": {
      "en": "英語",
      "de": "ドイツ語",
      "es": "スペイン語",
      "fr": "フランス語",
      "id": "インドネシア語",
      "it": "イタリア語",
      "jp": "日本語",
      "kr": "韓国語",
      "pl": "ポーランド語",
      "pt": "ポルトガル語",
      "ru": "ロシア語",
      "th": "タイ語",
      "tr": "トルコ語",
      "vt": "ベトナム語",
      "cn": "中国語",
      "tch": "中国語",
    },
    "kr": {
      "en": "영어",
      "de": "독일어",
      "es": "스페인어",
      "fr": "프랑스어",
      "id": "인도네시아어",
      "it": "이탈리아어",
      "jp": "일본어",
      "kr": "한국어",
      "pl": "폴란드어",
      "pt": "포르투갈어",
      "ru": "러시아어",
      "th": "태국어",
      "tr": "터키어",
      "vt": "베트남어",
      "cn": "중국어",
      "tch": "중국어-번체",
    },
    "pl": {
      "en": "Angielski",
      "de": "Niemiecki",
      "es": "Hiszpański",
      "fr": "Francuski",
      "id": "Indonezyjski",
      "it": "Włoski",
      "jp": "Japoński",
      "kr": "Koreański",
      "pl": "Polski",
      "pt": "Portugalski",
      "ru": "Rosyjski",
      "th": "Tajski",
      "tr": "Turecki",
      "vt": "Wietnamski",
      "cn": "Chiński",
      "tch": "Tradycyjny Chiński",
    },
    "pt": {
      "en": "Inglês",
      "de": "Alemão",
      "es": "Espanhol",
      "fr": "Francês",
      "id": "Indonésio",
      "it": "Italiano",
      "jp": "Japonês",
      "kr": "Coreano",
      "pl": "Polaco",
      "pt": "Português",
      "ru": "Russo",
      "th": "Tailandês",
      "tr": "Turco",
      "vt": "Vietnamita",
      "cn": "Chinês",
      "tch": "Chinês Tradicional",
    },
    "ru": {
      "en": "Английский",
      "de": "Немецкий",
      "es": "Испанский",
      "fr": "Французский",
      "id": "Индонезийский",
      "it": "Итальянский",
      "jp": "Японский",
      "kr": "Корейский",
      "pl": "Польский",
      "pt": "Португальский",
      "ru": "Русский",
      "th": "Тайский",
      "tr": "Турецкий",
      "vt": "Вьетнамский",
      "cn": "Китайский (упр.)",
      "tc": "Китайский (трад.)",
    },
    "th": {
      "en": "ภาษาอังกฤษ",
      "de": "ภาษาเยอรมัน",
      "es": "ภาษาสเปน",
      "fr": "ภาษาฝรั่งเศส",
      "id": "ภาษาอินโดนีเซีย",
      "it": "ภาษาอิตาลี",
      "jp": "ภาษาญี่ปุ่น",
      "kr": "ภาษาเกาหลี",
      "pl": "ภาษาโปแลนด์",
      "pt": "ภาษาโปรตุเกส",
      "ru": "ภาษารัสเซีย",
      "th": "ภาษาไทย",
      "tr": "ภาษาตุรกี",
      "vt": "ภาษาเวียดนาม",
      "cn": "ภาษาจีน",
      "tch": "ภาษาจีนดั้งเดิม",
    },
    "tr": {
      "en": "İngilizce",
      "de": "Almanca",
      "es": "İspanyolca",
      "fr": "Fransızca",
      "id": "Endonezce",
      "it": "İtalyanca",
      "jp": "Japonca",
      "kr": "Korece",
      "pl": "Lehçe",
      "pt": "Portekizce",
      "ru": "Rusça",
      "th": "Tay Dili",
      "tr": "Türkçe",
      "vt": "Vietnamca",
      "cn": "Çince",
      "tch": "Geleneksel Çince",
    },
    "vt": {
      "en": "Tiếng Anh",
      "de": "Tiếng Đức",
      "es": "Tiếng Tây Ban Nha",
      "fr": "Tiếng Pháp",
      "id": "tiếng In-đô-nê-xi-a",
      "it": "tiếng Ý",
      "jp": "Tiếng Nhật",
      "kr": "Tiếng Hàn",
      "pl": "tiếng Ba Lan",
      "pt": "Tiếng Bồ Đào Nha",
      "ru": "tiếng Nga",
      "th": "Tiếng thái",
      "tr": "tiếng Thổ Nhĩ Kỳ",
      "vt": "Tiếng Việt",
      "cn": "Tiếng Trung",
      "tch": "Tiếng Trung phồn thể",
    },
    "cn": {
      "en": "英语",
      "de": "德语",
      "es": "西班牙语",
      "fr": "法语",
      "id": "印尼语",
      "it": "意大利语",
      "jp": "日语",
      "kr": "韩语",
      "pl": "波兰语",
      "pt": "葡萄牙语",
      "ru": "俄语",
      "th": "泰语",
      "tr": "土耳其语",
      "vt": "越南语",
      "cn": "简体中文",
      "tch": "繁体中文",
    },
    "tch": {
      "en": "英語",
      "de": "德語",
      "es": "西班牙語",
      "fr": "法語",
      "id": "印尼語",
      "it": "意大利語",
      "jp": "日語",
      "kr": "韓語",
      "pl": "波蘭語",
      "pt": "葡萄牙語",
      "ru": "俄語",
      "th": "泰语",
      "tr": "土耳其語",
      "vt": "越語",
      "cn": "簡體中文",
      "tch": "繁體中文",
    }
  },
  i18nMap: {
    "en": "en",
    "de": "de",
    "es": "es",
    "fr": "fr",
    "id": "id",
    "it": "it",
    "ja": "jp",
    "ko": "kr",
    "pl": "pl",
    "pt": "pt",
    "ru": "ru",
    "th": "th",
    "tr": "tr",
    "vi": "vt",
    "zh": "cn",
    "zh-CN": "cn",
    "zh-TW": "tch",
  },
  htmlLangMap: {
    "en": "en",
    "de": "de",
    "es": "es",
    "fr": "fr",
    "id": "id",
    "it": "it",
    "jp": "ja",
    "kr": "ko",
    "pl": "pl",
    "pt": "pt",
    "ru": "ru",
    "th": "th",
    "tr": "tr",
    "vt": "vi",
    "cn": "zh",
    "tch": "zh",
  },
  langsKeyMap: {
    "en": "en",
    "de": "de",
    "es": "es",
    "fr": "fr",
    "id": "idn",
    "it": "ita",
    "jp": "jp",
    "kr": "kr",
    "pl": "pol",
    "pt": "pt",
    "ru": "ru",
    "th": "thai",
    "tr": "tur",
    "vt": "vt",
    "cn": "cn",
    "tch": "tch",
  },

  // auth apis
  userVerify: apiUrl + '/passport/user_verify',
  userSignOut: apiUrl + '/passport/user_signout',
  validateToken: apiUrl + '/passport/validate_token',

  // api
  plansApi: apiUrl + '/v1/paypal_subscription_plans',
  memberStatusApi: apiUrl + '/v1/purchase_memberstatus',
  userRemoveProfile: apiUrl + '/v1/user_remove_profile',
  audioTranscriptionsApi: apiUrl + '/v1/openai_audiotranscriptions',
  audioTranslationsApi: apiUrl + '/v1/openai_audiotranslations',
  chatCompletionsApi: apiUrl + '/v1/openai_chatcompletions',
  aiCreditConsume: apiUrl + '/v1/aicredit_consume',
  aiCreditRetrival: apiUrl + '/v1/aicredit_retrival'
}
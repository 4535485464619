import request from '../utils/request'
import constants from '../utils/constants'
import EncryptApiUtil from '../utils/encryptApiUtil.js'

export async function getUserInfo(token) {
  let req = {
    "token_id": token
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.validateToken,
    method: 'POST',
    data: encryptData.token,
  })

  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)

  return JSON.parse(decryptData.result.outjson || null)
}

export async function getMemberStatus(uid) {
  let req = {
    "ep_uid": uid,
    "from": 'web'
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.memberStatusApi,
    method: 'POST',
    data: encryptData.token
  })

  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)

  return decryptData.result
}

export async function getUserToken(authResult) {
  let res = await request({
    url: constants.userVerify,
    method: 'POST',
    data: { vfu: JSON.stringify(authResult) }
  })

  let token = res.data.slice(2)

  return token
}

export async function userSignOut(token) {
  let req = {
    "token_id": token
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  await request({
    url: constants.userSignOut,
    method: 'POST',
    data: encryptData.token,
  })
}

export async function userDelete(userInfo) {
  let req = {
    "ep_uid": userInfo.UID,
    "email": userInfo.Email,
    "uversion": userInfo.UVersion
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.userRemoveProfile,
    method: 'POST',
    data: encryptData.token,
  })

  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)

  return decryptData.result
}
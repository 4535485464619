<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="`#${iconName}`"></use>
  </svg>
</template>

<script>
  export default {
    props: ['iconName']
  }
</script>
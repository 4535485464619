import store from '../store/index'
import { ref, set } from 'firebase/database'
import { consumeMemberAICredits } from '@/api/aiTutor'

export async function initTodayData() {
  let historyData = store.getters.historyData
  let todayDate = getTodayDate()

  if (historyData) {
    let records = historyData.split(';').filter(item => item)
    let todayRecord = records.find(record => record.split(':')[0] === todayDate)

    if (!todayRecord) {
      updateHistoryData(0, 0)
    }
  }
}

export async function updateHistoryData(learnDuration, earnXP) {
  let userInfo = store.getters.userInfo
  let historyData = store.getters.historyData
  let todayDate = getTodayDate()
  let updateTime = Date.parse(new Date()) / 1000
  
  if (historyData) {
    // 查看有没有今天的数据
    let records = historyData.split(';').filter(item => item)
    let todayRecord = records.find(record => record.split(':')[0] === todayDate)
    if (todayRecord) {
      let durationAndXP = todayRecord.split(':')[1]
      let duration = Number(durationAndXP.split('_')[0])
      let xp = Number(durationAndXP.split('_')[1])
      let starChallenge = durationAndXP.split('_')[2] // app端有的数据会多一个星星挑战
      let newRecord = `${todayDate}:${duration + learnDuration}_${xp + earnXP}${starChallenge ? '_' + starChallenge : ''}`
      historyData = historyData.replace(todayRecord, newRecord)
    } else {
      todayRecord = `${todayDate}:${learnDuration}_${earnXP};`
      historyData = todayRecord + historyData
    }
  } else {
    historyData = `${todayDate}:${learnDuration}_${earnXP};`
  }

  if (userInfo) {
    await set(ref(window.db_srs, `users_private/${userInfo.UID}/learning_history`), {
      last_update_time: updateTime,
      record: historyData
    })
  }
  store.commit('set_historyData', historyData)
}

export async function updateAITutorHistoryData(key, historyData) {
  let userInfo = store.getters.userInfo
  if (userInfo) {
    await set(ref(window.db_aiTutor, `aiTutorHistory/${userInfo.UID}/en/${key}`), historyData)
  }
}

export async function updateAITutorHistoryDetailData(key, historyDetailData) {
  let userInfo = store.getters.userInfo
  if (userInfo) {
    await set(ref(window.db_aiTutor, `aiTutorHistoryDetail/${userInfo.UID}/en/${key}`), historyDetailData)
  }
}

export async function updateAITutorCreditsData(usedToken) {
  let userInfo = store.getters.userInfo
  let isMember = store.getters.isMember
  let orderID = store.getters.orderID
  let aiCreditsData = store.getters.aiCreditsData
  if (userInfo && aiCreditsData) {
    let { tokenConsumed, creditConsumed } = aiCreditsData

    if (isMember) {
      let memberAICredits = await consumeMemberAICredits(userInfo.UID, orderID, usedToken)
      let { StartDate, ExpiredDate, CreditGrant, CreditConsumed, TokenConsumed } = memberAICredits
      let aiCreditsData = {
        startDate: StartDate,
        expiredDate: ExpiredDate,
        creditGrant: CreditGrant,
        creditConsumed: CreditConsumed,
        tokenConsumed: TokenConsumed
      }
      store.commit('set_aiCreditsData', aiCreditsData)
    } else {
      tokenConsumed += usedToken
      creditConsumed = Math.round(tokenConsumed / 400)
      aiCreditsData.tokenConsumed = tokenConsumed
      aiCreditsData.creditConsumed = creditConsumed
      await set(ref(window.db_aiTutor, `aiTutorCredits/${userInfo.UID}/freeTrailCredits`), aiCreditsData)
    }
  }
}

function getTodayDate () {
  let now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let day = now.getDate()
  if (month < 10) month = "0" + month
  if (day < 10) day = "0" + day
  let date = '' + year + month + day

  return date
}
import request from '../utils/request'
import constants from '../utils/constants'
import EncryptApiUtil from '../utils/encryptApiUtil.js'

export async function chat(uid, messages) {
  let req = {
    "ep_uid": uid,
    "model": "gpt-3.5-turbo",
    "temperature": 0.7,
    "n": 1,
    "messages": JSON.stringify(messages),
    "uversion": "web"
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.chatCompletionsApi,
    method: 'POST',
    data: encryptData.token
  })
  
  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)
  
  return JSON.parse(decryptData.result.completions)
}

// 纠错和评分
export async function checkAndRating(uid, dialogA, dialogB) {
  let prompt = "Act as a spoken English tutor, evaluate a conversation between A and B. If B's response is digressive, reply \"OFF@@@@\" and suggest an \"Appropriate sentence:\" for B. Otherwise, strictly check B's grammar and appropriateness. If B fails either check, reply \"Rating:\" and rate B's response with a score from 1 to 7 (as in 1/10, 2/10, etc.) and suggest a colloquial \"Appropriate sentence:\" for B, Keep the sentence under 20 words. If B passes both checks, reply \"Rating:\" from 8 to 10 (as in 8/10, 9/10, etc.) only."
  + `\nA: ${dialogA}`
  + `\nB: ${dialogB}`
  let messages = [{"role": "system", "content": prompt}]

  return await chat(uid, messages)
}

// 获取建议的回答
export async function getSuggestions(uid, dialogs) {
  let prompt = "B is practicing a conversation with A. You are a spoken English tutor. Please suggest 3 possible responses for B. Keep each sentence under 15 words. Follow the format \"1./2./3.\"."
  if (dialogs.length > 0) prompt += `\nA: ${dialogs[0]}`
  if (dialogs.length > 1) prompt += `\nB: ${dialogs[1]}`
  if (dialogs.length > 2) prompt += `\nA: ${dialogs[2]}`
  prompt += "\nB: "
  let messages = [{"role": "system", "content": prompt}]

  return await chat(uid, messages)
}

// 语音转文本
export async function AudioTranscriptions(uid, recordData) {
  let token = {
    "ep_uid": uid,
    "model": "whisper-1",
    "uversion": "web",
    "language": "en"
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(token))

  let res = await request( {
    url: constants.audioTranscriptionsApi,
    method: 'POST',
    headers: encryptData.token,
    data: recordData
  })

  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)

  return JSON.parse(decryptData.result.audio_text)
}

// 会员获取AI点数
export async function getMemberAICredits(uid, orderid) {
  let req = {
    "ep_uid": uid,
    "orderid": orderid,
    "from": "web"
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.aiCreditRetrival,
    method: 'POST',
    data: encryptData.token
  })
  
  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)
  
  return decryptData.result.aicredit_curcircle_detail
}

// 会员消耗AI点数
export async function consumeMemberAICredits(uid, orderid, tokenConsumed) {
  let req = {
    "ep_uid": uid,
    "orderid": orderid,
    "token_consumed": tokenConsumed,
    "consume_date_ms": new Date().getTime(),
    "consume_detail_pointer": "",
    "from": "web"
  }

  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  let res = await request({
    url: constants.aiCreditConsume,
    method: 'POST',
    data: encryptData.token
  })
  
  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  decryptData = JSON.parse(decryptData)
  
  return decryptData.result.aicredit_curcircle_detail
}
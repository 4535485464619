import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'
import constants from '../utils/constants'
import { getToken, removeToken } from '../utils/authUtil'
import { getUserInfo, userSignOut } from '../api/auth'
import { signOut } from 'firebase/auth'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    userLanguage: '',
    token: getToken(),
    userInfo: null,
    isMember: false,
    isHaveFreeTrial: false,
    isNewUser: false,
    accountType: 'free',
    orderID: '',
    productsInfo: null,
    historyData: null,  // 20221101:100_30; 日期:学习时长(s)_获得xp
    aiTutorData: null,  // AITutor数据
    aiCreditsData: null,  // AI点数数据
    aiTutorHistoryData: null,  // aiTutor历史数据
    updateTime: localStorage.getItem('updateTime'),  // 根据此参数判断是否同步数据
    createTime: localStorage.getItem('createTime'),  // 第一次访问站点的时间
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    userLanguage: state => {
      return state.userLanguage || localStorage.getItem('lang')
    },
    token: state => state.token,
    userInfo: state => state.userInfo,
    isMember: state => state.isMember,
    isHaveFreeTrial: state => state.isHaveFreeTrial,
    isNewUser: state => state.isNewUser,
    accountType: state => state.accountType,
    orderID: state => state.orderID,
    productsInfo: state => state.productsInfo,
    historyData: state => state.historyData,
    aiTutorData: state => state.aiTutorData,
    aiCreditsData: state => state.aiCreditsData,
    aiTutorHistoryData: state => state.aiTutorHistoryData,
    updateTime: state => state.updateTime,
    createTime: state => {
      if (state.createTime) {
        return state.createTime
      } else {
        let now = Date.parse(new Date())
        localStorage.setItem('createTime', now)
        return now
      }
    }
  },
  mutations: {
    reset_state(state) {
      Object.assign(state, getDefaultState())
    },
    set_userLanguage(state, lang) {
      state.userLanguage = lang
      localStorage.setItem('lang', lang)
      i18n.locale = lang
      document.querySelector('html').setAttribute('lang', constants.htmlLangMap[lang])
    },
    set_userInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    set_isMember(state, isMember) {
      state.isMember = isMember
    },
    set_isHaveFreeTrial(state, isHaveFreeTrial) {
      state.isHaveFreeTrial = isHaveFreeTrial
    },
    set_isNewUser(state, isNewUser) {
      state.isNewUser = isNewUser
    },
    set_accountType(state, accountType) {
      state.accountType = accountType
    },
    set_orderID(state, orderID) {
      state.orderID = orderID
    },
    set_productsInfo(state, productsInfo) {
      state.productsInfo = productsInfo
    },
    set_historyData(state, historyData) {
      state.historyData = historyData
    },
    set_aiTutorData(state, aiTutorData) {
      state.aiTutorData = aiTutorData
    },
    set_aiCreditsData(state, aiCreditsData) {
      state.aiCreditsData = aiCreditsData
    },
    set_aiTutorHistoryData(state, aiTutorHistoryData) {
      state.aiTutorHistoryData = aiTutorHistoryData
    },
    set_updateTime(state, updateTime) {
      state.updateTime = updateTime
      localStorage.setItem('updateTime', updateTime)
    }
  },
  actions: {
    async validateToken({ commit, state }) {
      let userInfo = await getUserInfo(state.token)
      if (userInfo) {
        commit('set_userInfo', userInfo)
      }
      return Promise.resolve(userInfo)
    },
    async logout({ commit, state }) {
      let token = state.token
      await signOut(window.auth)
      await signOut(window.auth_core)
      await signOut(window.auth_srs)
      await signOut(window.auth_aiTutor)
      await userSignOut(token)
      removeToken()
      commit('reset_state')
      localStorage.clear()
      window.location.href = constants.domain
    }
  }
})
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import i18n from './i18n'
import '@/assets/styles/reset.scss'
import '@/assets/styles/index.scss'
import '@/plugins/element.js'
import '@/utils/iconfont.js'
import constants from '@/utils/constants'
import MyIcon from '@/components/MyIcon'

Vue.component('my-icon', MyIcon)

// firebase
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'

const DB_core = Object.assign({}, constants.firebaseConfig, {
  databaseURL: "https://speakey-userdata-core.firebaseio.com"
})

const DB_srs = Object.assign({}, constants.firebaseConfig, {
  databaseURL: "https://speakey-userdata-srs.firebaseio.com"
})

const DB_aiTutor = Object.assign({}, constants.firebaseConfig, {
  databaseURL: "https://speakey-ai-tutor-userdata.firebaseio.com"
})

const DB_aiTutor_list = Object.assign({}, constants.firebaseConfig, {
  databaseURL: "https://speakey-ai-tutor.firebaseio.com"
})

const app = initializeApp(constants.firebaseConfig)
const app_db_core = initializeApp(DB_core, 'app_db_core')
const app_db_srs = initializeApp(DB_srs, 'app_db_srs')
const app_db_aiTutor = initializeApp(DB_aiTutor, 'app_db_aiTutor')
const app_db_aiTutor_list = initializeApp(DB_aiTutor_list, 'app_db_aiTutor_list')

// init auth
const auth = getAuth(app)
const auth_core = getAuth(app_db_core)
const auth_srs = getAuth(app_db_srs)
const auth_aiTutor = getAuth(app_db_aiTutor)
Vue.prototype.auth = auth
Vue.prototype.auth_core = auth_core
Vue.prototype.auth_srs = auth_srs
Vue.prototype.auth_aiTutor = auth_aiTutor
window.auth = auth
window.auth_core = auth_core
window.auth_srs = auth_srs
window.auth_aiTutor = auth_aiTutor

// init database
const db_core = getDatabase(app_db_core)
const db_srs = getDatabase(app_db_srs)
const db_aiTutor = getDatabase(app_db_aiTutor)
const db_aiTutor_list = getDatabase(app_db_aiTutor_list)
Vue.prototype.db_core = db_core
Vue.prototype.db_srs = db_srs
Vue.prototype.db_aiTutor = db_aiTutor
Vue.prototype.db_aiTutor_list = db_aiTutor_list
window.db_core = db_core
window.db_srs = db_srs
window.db_aiTutor = db_aiTutor
window.db_aiTutor_list = db_aiTutor_list

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app")

import JSEncrypt from 'jsencrypt'
import AES from './encryption/aesTool/aesAll'
import BASE_64 from './encryption/base64/base64Util'

export default {
  encrypt: encrypt,
  decrypt: decrypt
}

function encrypt(str) {
  let keyStr = genkey(16)
  let ivStr = genkey(16)
  let key = AES.CryptoJS.enc.Utf8.parse(keyStr)
  let iv = AES.CryptoJS.enc.Utf8.parse(ivStr)

  let param = {
    enKey: rsaEncrypt(keyStr),
    enIV: rsaEncrypt(ivStr),
    enContent: aesEncrypt(str, key, iv),
    caller: aesEncrypt('web', key, iv)
  }

  let resultObj = {
    key,
    iv,
    token: { "token": BASE_64.encode(JSON.stringify(param)) }
  }

  return resultObj
}

function decrypt(str, key, iv) {
  return aesDecrypt(str, key, iv)
}

// 生成指定位数的随机值
function genkey(num) {
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
  })

  return uuid.slice(0, num)
}

// rsa 加密
function rsaEncrypt(str) {
  let encryptor = new JSEncrypt()
  let pubKey = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDO4FOsejugfKtwQKqkVRWZeduFVspgRTng001qz4BYVBaB3U3E7jYfW1T/ksnmS/YtGtsTufxdko9mqNsURcD42VnHPiks4F5rnoyl5/lvpT3OVX1yrQfB8mNEUHD2bQ6ys/e8r60+/wsfDXSD1NiT1Nc/vg+sPCBVrkwuSl5JhQIDAQAB-----END PUBLIC KEY-----'
  encryptor.setPublicKey(pubKey)
  return encryptor.encrypt(BASE_64.encode(str))
}

// aes 加密
function aesEncrypt(str, key, iv) {
  return AES.CryptoJS.AES.encrypt(BASE_64.encode(str), key, {
      iv: iv,
      mode: AES.CryptoJS.mode.CBC,
      padding: AES.CryptoJS.pad.Pkcs7
  }).toString()
}

// aes 解密
function aesDecrypt(str, key, iv) {
  return AES.CryptoJS.AES.decrypt(str, key, {
      iv: iv,
      mode: AES.CryptoJS.mode.CBC,
      padding: AES.CryptoJS.pad.Pkcs7
  }).toString(AES.CryptoJS.enc.Utf8)
}